<template>
  <div class="batch-create-merchandise" style="height: auto">
    <!-- 標題 -->
    <div class="single-page-tag mb-3">
      <p class="sp-start sp-text fw-bolder">FB文章內容</p>
    </div>
    <!-- 注意事項 -->
    <div class="mx-3 mb-2">
      <button
        class="tw-btn tw-btn-danger"
        v-if="!showPrecautions"
        @click="showPrecautions = true"
      >
        開啟注意事項，請務必詳細閱讀，避免影響您的權利
      </button>
      <button
        class="tw-btn tw-btn-secondary"
        v-else
        @click="showPrecautions = false"
      >
        關閉注意事項
      </button>
    </div>
    <!-- 注意事項 -->
    <div class="fw-bolder alert alert-danger mb-2 mx-3" v-if="showPrecautions">
      <p class="mb-2">
        1. 請注意 ! 若一次新增太多商品會導致您等待的時間過長，請斟酌新增數量~
      </p>
      <p class="mb-2">
        2. 一經離開此頁面，若尚未按下新增按鈕，資料則不會自動幫您儲存 !
      </p>
      <p class="text-break">
        3.
        在點擊"確認建立商品之後"，系統會將所有商品除了圖片以外的資訊先建立起來，後續再補上圖片，所以有可能在建立完之後商品管理頁面看不到剛剛所新增的圖片，但過幾分鐘後重新整理即可看見
      </p>
    </div>
    <!-- 內容 -->
    <BatchCreateMerchandise></BatchCreateMerchandise>
  </div>
  <!-- 空的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref=""
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bolder">Title</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">...</div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button type="button" class="tw-btn tw-btn-success">確認</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// components
import BatchCreateMerchandise from '@/components/merchandise/BatchCreateMerchandise.vue'

export default {
  components: { BatchCreateMerchandise },
  data() {
    return {
      showPrecautions: false,
    }
  },
}
</script>